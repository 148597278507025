export const isCopilotPage = () => {
    let is_copilot_page_state = useIsCopilotPageState();
    return is_copilot_page_state.value;

    /*
    if (typeof(window) != 'undefined') {
        if (window.location.href.includes('agent=1')) {
            return true;
        } else {
            return false;
        }
    } else {
        const route = useRoute();
        let copilot = route.query.agent;
        if ((copilot) && (copilot == '1')) {
            return true;
        } else {
            return false;
        }
    }
    */
    
}